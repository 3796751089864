@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

.footer {
  @apply pt-[110px]  flex flex-col gap-3 text-white w-60 max-sm:mx-auto;
}

@media (max-width: 768px) {
  header {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }

  header .flex {
    flex-direction: column;
    align-items: center;
  }

  header .flex > div {
    width: 100%;
    margin-bottom: 1rem;
  }

  /* Centralizar a logo no mobile */
  header img {
    height: 3rem;
    padding-top: 0;
    margin: 0 auto;
  }

  /* Ajustar título e remover barra branca */
  header h1 {
    font-size: 1.25rem;
    padding-left: 0;
    border: none; /* Remover borda no título */
  }

  /* Remover a borda ao redor do título */
  header .border-l-2 {
    border: none; /* Remover a classe border-l-2 */
    padding-left: 0; /* Ajustar o padding */
  }

  /* Botão responsivo */
  header button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border-radius: 0.5rem;
  }
}
